import { PricingFilter } from "../PricingFilter";

export class PricingFilterFactory {
    static instantiate(obj){
        return new PricingFilter(obj)
    }

    static instantiateEmpty(){
        return new PricingFilter({
            fecha_cotizacion_inicio: null,
            fecha_cotizacion_fin: null,
            valor_cotizado_inicio: null,
            valor_cotizado_fin: null,
            rango_valor: [2000, 65000],
            numero_materiales_inicio: null,
            numero_materiales_fin: null,
            rango_materiales: [100, 500],
            id_estado: null,
            id_oportunidad: null,
        })
    }
}