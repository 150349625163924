<template>
    <section class="proveedores-ver-cotizaciones container-fluid overflow-auto custom-scroll mt-2">
        <titulo-divisor titulo="Cotizaciones">
            <div class="row">
                <div class="col auto d-none">
                    <el-input v-model="buscarListado" placeholder="Buscar proveedores" size="small" />
                </div>
                <div v-if="cantFiltros == 0" class="col-auto my-auto pl-1 icon-option">
                    <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d ml-2 cr-pointer" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                    </el-tooltip>
                </div>
                <div v-if="cantFiltros > 0" class="col-auto filtro-contador my-auto pl-1">
                    <div class="d-flex border-general br-4">
                        <button class="btn btn-square32-5d cr-pointer" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                        <div class="d-middle px-2">
                            <p class="f-12">3</p>
                            <i class="icon-close-circle f-11 text-muted pl-1 cr-pointer" @click="limpiarFiltro" />
                        </div>
                    </div>
                </div>
            </div>
        </titulo-divisor>
        <div class="d-flex justify-content-lg-end">
            <search class="w-25" :data-to-search="listadoCotizaciones.data" :auto-search="false" placeholder="Buscar cotizacion" @filtered="refreshQuoteList" />
        </div>
        <tabla-general :usar-servidor="true" :usar-paginacion="true" :mostrar-buscador="false" :servidor-data="listadoCotizaciones" @paginar="listarProveedoresCotizaciones">
            <el-table-column label="Cotización" prop="referencia" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 f-600 cr-pointer" @click="verCotizacion(scope.row.id)">
                        {{ scope.row.referencia }}
                    </p>
                </template>
            </el-table-column>
             <el-table-column label="Oportunidad" prop="oportunidad">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">
                        {{ scope.row.oportunidad }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Materiales cotizados" prop="materiales_cotizados" align="center" width="95">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.materiales_cotizados }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor total" prop="valor" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ formatoMoneda(scope.row.valor) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Fecha cotización" prop="fecha_cotizacion" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.fecha_cotizacion }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Días de crédito" prop="dias_credito" align="center" width="120">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.dias_credito }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Estado" prop="estado">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 d-middle">
                        <i :class="`${scope.row.estado ? 'icon-file-send text-green' : 'icon-file-send text-info2'} f-20`" />
                        {{ scope.row.estado ? 'Recibido' : 'Enviado' }}
                    </p>
                </template>
            </el-table-column>
        </tabla-general>
        <!-- partials  -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-12 my-3">
                    <p class="input-label-top">Oportunidad</p>
                    <el-select v-model="pricingFilter.id_oportunidad" placeholder="Seleccionar oportunidad" size="small" class="w-100">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Estado</p>
                    <el-input v-model="pricingFilter.id_estado" placeholder="Estado" size="small" class="w-100" />
                </div>
                <div class="col-12">
                    <p class="input-label-top">Rango fecha cotización</p>
                </div>
                <div class="col-6 mb-3">
                    <p class="input-label-top">Desde</p>
                    <el-date-picker v-model="pricingFilter.fecha_cotizacion_inicio" type="date" placeholder="Seleccionar un día" size="small" class="w-100" />
                </div>
                <div class="col-6 mb-3">
                    <p class="input-label-top">Hasta</p>
                    <el-date-picker v-model="pricingFilter.fecha_cotizacion_fin" type="date" placeholder="Seleccionar un día" size="small" class="w-100" />
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Rango de valor cotizado</p>
                    <el-slider v-model="pricingFilter.rango_valor" ref="rangeValorCotizado" range :max="10000" :format-tooltip="formatTooltipRange" />
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Rango de materiales cotizados</p>
                    <el-slider v-model="pricingFilter.rango_materiales" range :max="10000" :format-tooltip="formatTooltipRangeMaterials" />
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="limpiarFiltro"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filtrarCotizaciones"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import { PricingFilterFactory } from '~/domain/filters/proveedores/cotizaciones/factories/PricingFilterFactory'
import {mapGetters, mapActions, mapMutations} from 'vuex'

export default {
    data(){
        return{
            buscarListado:'',
            options: [
                {
                    value: 'Option1',
                    label: 'Option1'
                }, 
                {
                    value: 'Option2',
                    label: 'Option2'
                }, 
                {
                    value: 'Option3',
                    label: 'Option3'
                }, 
                {
                    value: 'Option4',
                    label: 'Option4'
                }, 
                {
                    value: 'Option5',
                    label: 'Option5'
                }
            ],
            cantFiltros: 0,
            valueSelect: '',
            estadoFilter: '',
            pickerFechaInicio: '',
            pickerFechaFinal: '',
            valueRangeValoresCotizado: [2000, 6500],
            valueRangeValoresMateriales: [100, 500],
            pricingFilter: PricingFilterFactory.instantiateEmpty()
        }
    },
    computed: {
        ...mapGetters({
            listadoCotizaciones: 'proveedores/ver/ver/provider_quotes'
        }),
        id_proveedor(){
            return this.$route.params?.id_proveedor
        }
    },
    async mounted(){
        const { query } = this.$route
        if(Object.keys(query).length){
            this.pricingFilter = PricingFilterFactory.instantiate(query)
            await this.filtrarCotizaciones(this.pricingFilter)
        } else {
            await this.obtenerListadoCotizaciones({id_proveedor: this.id_proveedor})
        }
    },
    methods:{
        ...mapActions({
            obtenerListadoCotizaciones: 'proveedores/ver/ver/Action_get_provider_quotes',
            regenerarListadoCotizaciones: 'proveedores/ver/ver/Action_regenerate_provider_quotes',
        }),
        ...mapMutations({
            setListadoCotizaciones: 'proveedores/ver/ver/set_provider_quotes'
        }),

         abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        async filtrarCotizaciones(){
            this.pricingFilter.fecha_cotizacion_inicio = this.$options.filters['helper-fecha'](this.pricingFilter.fecha_cotizacion_inicio, 'YYYY-MM-DD')            
            this.pricingFilter.fecha_cotizacion_fin = this.$options.filters['helper-fecha'](this.pricingFilter.fecha_cotizacion_fin, 'YYYY-MM-DD')            
            this.pricingFilter.valor_cotizado_inicio = this.pricingFilter.rango_valor[0]
            this.pricingFilter.valor_cotizado_fin = this.pricingFilter.rango_valor[1]
            this.pricingFilter.numero_materiales_inicio = this.pricingFilter.rango_materiales[0]
            this.pricingFilter.numero_materiales_fin = this.pricingFilter.rango_materiales[1]

            this.cantFiltros = 0
            Object.entries(this.pricingFilter).map(entry => this.cantFiltros++)
            this.cantFiltros = this.cantFiltros - 4
            
            this.$router.push({query: {...this.pricingFilter.clean(), rango_valor: null, rango_materiales: null}})

            try {
                await this.obtenerListadoCotizaciones({id_proveedor: this.id_proveedor, payload: {...this.pricingFilter.clean(), rango_valor: null, rango_materiales: null}})
                this.$notify({title:'Filtrado', type: 'success'})
            }catch(e){
                this.$notify({title:'Ha ocurrido un error al filtrar', type: 'success'})
            } finally {
                this.$refs.abrirModalFiltros.toggle()
            }
        },
        async limpiarFiltro(){
            this.pricingFilter = PricingFilterFactory.instantiateEmpty()

            await this.obtenerListadoCotizaciones({id_proveedor: this.id_proveedor})
            this.$refs.abrirModalFiltros.toggle()
            this.cantFiltros = 0
        },
        async listarProveedoresCotizaciones(page = 1) {
            await this.obtenerListadoCotizaciones({ id_proveedor: this.id_proveedor, payload: { page } })
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        formatTooltipRangeMaterials(val){
            return val + 'unidades'
        },
        verCotizacion(id_cotizacion){
            this.$router.push({ name: 'proveedores.ver.cotizaciones.ver', params: { id_cotizacion: id_cotizacion } })
        },
        async refreshQuoteList(data){
            if(data.length){
                this.setListadoCotizaciones({...this.listadoCotizaciones, data})
                this.$notify({title: `Busqueda realizada: ${data.length} resultados`, type: 'success'})
            } else {
                this.regenerarListadoCotizaciones()
                this.$notify({title: `Sin resultados`, type: 'info'})
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.proveedores-ver-cotizaciones{
    height: calc(100vh - 445px);
    .icon-file-send{
        &::before{
            margin-left: 0px;
        }
    }
}
</style>