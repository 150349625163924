import { Filter } from '~/domain/core/Filter';

export class PricingFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: ['id_estado', 'id_oportunidad', 'fecha_cotizacion_inicio', 'fecha_cotizacion_fin', 
            'valor_cotizado_inicio', 'valor_cotizado_fin', 'numero_materiales_inicio', 'numero_materiales_fin', 'rango_valor', 'rango_materiales']
        });
    }
}